<template>
  <div>
    <div class="header zi-100" style="position:relative">
      <div v-if="back" class="back cursor-pointer ms-1" @click="goBack()">
        <img src="@/assets/img/corse-icon.png" class="corse-icon cursor-pointer">
      </div>
      <div v-else>

      </div>

      <div v-if="logo != null" class="title">
        <img :src="logo" class="logo" alt="">
      </div>
      <div v-else-if="title != null" class="title">
        {{ title }}
      </div>
      <div v-else>
        
      </div>

      <div v-if="btn != null" class="b">
        <router-link :to="btnLink" class="button rounded-50px">{{ btn }}</router-link>
      </div>
      <div v-else>

      </div>
    </div>
  </div>
</template>
  
<script>
  export default {
    name: 'HeaderNav',
    props: {
      back:{
        type: Boolean,
        required: false,
        default: true
      },
      title: {
        type: String,
        required: false
      },
      logo: {
        type: String,
        required: false
      },
      btn: {
        type: String,
        required: false
      },
      btnLink: {
        type: String,
        required: false
      }
    },
    data() {
      
    },
    methods: {
      goBack() {
        this.$router.back();
      }
    }
  };
</script>

<style>

  .header {
    width: 100%;
    /* border-bottom:0.5px solid #5395A3; */
  }
  
  .logo {
    width: 120px;
    height: 31px;
  }
  
  .corse-icon {
    height: 30px;
  }
  
  .title{
    color: #FFFFFF;
    font-size: 30px;
  }
</style>