<template>
    <!-- <div class="inline-container">
        <img 
            src="@/assets/img/back-icon.png" 
            alt="Back Icon" 
            class="corse-icon" 
            @click="goBack"
        >
        <h6>Buy Token</h6>
    </div> -->
    <div class="home bg-img">
        <HeaderNav title="Buy Token" />
        <div class="content">
            <div class="text">
                <p>Pre sale is ready, just grab the coins as you want.</p>
            </div>
            <div class="c-show clearfix">
                <span class="text-start flex-grow-1">Pre Sale End in</span>
                <span class="text-end">{{ saleEndInDays() }} Days</span>
            </div>
            <div class="c-show clearfix">
                <span class="text-start flex-grow-1">Pre Sale Token Price</span>
                <span class="text-end">${{ $store.state.Setting.settings.sale_token_price }}</span>
            </div>
            <div class="c-show clearfix">
                <span class="text-start flex-grow-1">Token Sold in Pre Sale</span>
                <span class="text-end">{{ $store.state.Setting.settings.sale_token_available }}</span>
            </div>
            <button class="full-width-btn" @click="goToBuyTokens">Buy Tokens Now</button>
        </div>
    </div>
</template>

<script>
import HeaderNav from '/src/components/layout/header.vue';
  
export default {
    name: 'BuyToken1',
    components: {
        HeaderNav
    },
    methods:{
        goBack() {
           this.$router.go(-1);
        },
        goToBuyTokens() {
            this.$router.push({ name: 'buy/token-2' });
        },
        saleEndInDays() {
            let startDate = new Date();
            let endDate = this.parseDate(this.$store.state.Setting.settings.sale_end_at);
            const msPerDay = 1000 * 60 * 60 * 24;
            const diffInMs = endDate - startDate;
            return Math.floor(diffInMs / msPerDay);
        },
        parseDate(dateString) {
            const [day, month, year] = dateString.split('-').map(Number);
            return new Date(year, month - 1, day);
        },
    }
}
</script>

<style scoped>
.inline-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5395A3;
    width: 100%;
    padding: 30px 0;
}

.inline-container img.corse-icon {
    margin-left: 24px;
    cursor: pointer;
}

.inline-container h6 {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    flex: 1;
}
.text{
    padding: 10px;
    text-align: center;
    color: #fff;
}
.tokan-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
    background: #17313F;
    color: #fff;
    border-radius: 10px;
    border: 1px solid #5395A3;
}
.full-width-btn {
    /* padding: 10px; */
    /* margin: 10px; */
    margin-top: 30px;
    width: 100%;
    padding: 15px 0;
    background-color: #5395A3;
    color: #fff;
    border: none;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    border-radius: 10px;
}
</style>
