<template>
  <div class="home">
    <!-- <div class="inline-container">
      <img 
        src="@/assets/img/back-icon.png" 
        alt="Back Icon" 
        class="corse-icon" 
        @click="goBack"
      >
        <h6>Withdraw</h6>
    </div> -->
    <HeaderNav title="Leaderboard" />
        <div class="content">
        <div class="withdraw">
            <div class="start">
                <p>Token Balance</p>
                <h3>{{ $store.state.User.user.coin_kuvt }} <small>KUVT</small></h3>
                <h3>{{ $store.state.User.user.coin_kvts }} <small>KVTS</small></h3>
            </div>
            <div class="end">
                <img src="@/assets/img/withdraw-img.png" alt="Withdraw Image">
            </div>
        </div>
        <div class="text">
            <p>Enter your Polygon netwrok address where you want to get your coins withdraw.</p>
        </div>
        
        <div>
          <input type="text" class="wallet-address-input" placeholder="Enter Walltet Address" v-model="withdraw_address" />
        </div>

        <div class="check-box-div">
            <p><input type="checkbox" v-model="isWalletAddressConfirm"> I Confirm that my wallet address is correct</p>
            <p><input type="checkbox" v-model="isWarningConfirm"> I Understand tokens cannot be recovered</p>
        </div>
        
        <div class="position-fixed" style="bottom:10px; width:calc(100% - 20px);">
          <button class="btn w-100" @click="updateWalletAddress">Confirm</button>
        </div>

        <div v-if="isPopupVisible" class="popup-container" @click.self="closePopup">
        <div class="popup-content">
            <img src="@/assets/img/withdraw-success.png" alt="Success Icon">
            <h2>Withdraw Success</h2>
            <p>Wallet address has been updated successfully.</p>
            <button class="go-back-btn" @click="closePopup">Go Back</button>
        </div>
        </div>
      </div>
  </div>

</template>
<script>
// import HeaderNav from '/src/components/layout/header.vue';
import HeaderNav from '/src/components/layout/header.vue';
  
export default {
  name: 'WithDraw',
  
  data() {
    return {
      isPopupVisible: false,
      isWalletAddressConfirm: false,
      isWarningConfirm: false,
      withdraw_address: null,
    };
  },
  components: {
      HeaderNav
  },
  mounted(){
    this.withdraw_address = this.$store.state.User.user.wallet_address;
  },
  methods: {
    showPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    async updateWalletAddress(){

      if(this.withdraw_address == null){
        alert('Please enter wallet address');
        return;
      }

      if(this.isWalletAddressConfirm == false){
        alert('Please confirm wallet address');
        return;
      }

      if(this.isWarningConfirm == false){
        alert('Please confirm if wallet address is wrong all coins will lost');
        return;
      }

      const response = await fetch(`https://miniapp.kuveritnetwork.com/admin/api/user/update/wallet_address`,{
        method: 'Post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "wallet_address": this.wallet_address,
          "user_id": this.$store.state.User.user.id,
        })
      });

      let data = await response.json();
      data = data.data;
      if(data.status == 1){
        this.showPopup();
      }else{
        alert('Something went wrong');
      }
    },
  }
}
</script>
<style scoped>
.inline-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
}

.inline-container img.corse-icon {
  margin-top: 55px;
  margin-right: 10px;
  cursor: pointer;
}

.inline-container h6 {
  margin: 0;
  margin-top: 55px;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  flex-grow: 1;
}

.withdraw {
    /* padding: 10px; */
    /* margin: 0 auto; */
    /* width: calc(100% - 70px); */
    /* height: 114px; */
    border-radius: 10px;
    background: url('@/assets/img/withdraw-bg.png') no-repeat center center;
    background-size: cover;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 20px; Add some padding */
}

.withdraw .start {
    text-align: left;
}
.start p{
    margin-left: 20px;
    font-weight: 600;
    font-size: 14px;
}
.start h3{
    font-weight: 600;
    font-size: 32px;
    margin-left: 20PX;
}
.start small{
   font-weight: 500;
   font-size: 14px;
   color: #fff; 
}



.withdraw .end img {
   margin-right: 20px;
}
.text{
    /* padding: 10px; */
    margin: 10px;
    color: #fff;
    text-align: center;
    font-weight: 400;
    font-size: 16px;

}
/* .number-card{
    background: #17313F;
    border: 1px solid #17313F;
    border-radius: 20px;
    color: #fff;
    text-align: center;
}
.number-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
} */


.wallet-address-input, .wallet-address-input::placeholder {
  text-align: center;
}

.check-box-div{
    margin: 15px 0px;
    /* padding: 10px; */
    color: #fff;
}
.check-box-div p{
    font-weight: 400;
    font-size: 14px;
    columns: #fff;
}
.check-box-div input[type="checkbox"] {
    border: 1px solid #fff;
    background-color: #17313f !important;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    cursor: pointer;
    margin-right: 8px;
    position: relative;
}

.check-box-div input[type="checkbox"]:checked {
    background-color: blue !important;
    border: 1px solid #17313f;
    color: #fff;
}
.check-box-div input[type="checkbox"]:checked::after {
    content: '✔';
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.full-width-btn {
    /* padding: 10px; */
    /* margin: 10px; */
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    /* padding: 15px 0; */
    background-color: #5395A3;
    color: #fff;
    border: none;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    border-radius: 10px;
}

.full-width-btn:hover {
    background-color: #1f4a61;
}

.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.popup-content {
  background-color: #1E1E1E;
  border-radius: 10px;
  width: 300px;
  padding: 20px;
  text-align: center;
  color: #fff;
}

.popup-content img {
  width: 50px;
  margin-bottom: 20px;
}

.go-back-btn {
  background-color: #5395A3;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 50px;
}

.go-back-btn:hover {
  background-color: #1f4a61;
}

.full-width-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  /* width: 95%; */
  padding: 15px 0;
  background-color: #5395A3;
  color: #fff;
  border: none;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

/* .full-width-btn:hover {
  background-color: #1f4a61;
} */
</style>