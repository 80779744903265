export default {
  namespaced: true,
  state: {
    users: [],
    yourRank: 0,
    callApi: false,
  },
  mutations: {
    setUsers(state, value) {
      state.users = value;
    },
    setYourRank(state, value) {
      state.yourRank = value;
    },
    setCallApi(state, value) {
      state.callApi = value;
    },
    
  },
  actions: {
    
  },
  getters: {
    
  },
};