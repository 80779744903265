<template>
    <div v-if="$store.state.Other.showFirstScreen" ref="firstpage">
      <FirstPage />
    </div>
    <div class="home bg-img" v-else>
      <!-- <HeaderNav logo="/src/assets/img/logo.png" btn="Buy Token" /> -->
      <!-- <HeaderNav :back="false" logo="/assets/img/logo.png" /> -->
      <div class="content" style="padding-top:10px;">
        <!-- <div class="card-1" v-if="$store.state.User != null && $store.state.User.user != null">
          <div class="item">
            <h4 class="number-text">{{ $store.state.User.user.coin_kuvt }} <small>KUVT</small></h4>
            <router-link to="/buy/token-1" class="button">Buy Token</router-link>
          </div>
          <div class="item">
            <h4 class="number-text">{{ $store.state.User.user.coin_kvts }} <small>KVTS</small></h4>
            <button class="button" @click="goToWithdraw">Withdraw</button>
          </div>
        </div> -->
        <div class="position-card" v-if="$store.state.User.user"> 
            <div class="left-div">
              <p>{{ $store.state.User.user.name }}</p>
              <p>@{{ $store.state.User.user.user_name }}</p>
            </div>
            <div class="right-div" v-if="$store.state.User">
              <h3>{{ $store.state.User.user.coin_kuvt }} <small>KUVT</small></h3>
              <h3>{{ $store.state.User.user.coin_kvts }} <small>KVTS</small></h3>
            </div>
        </div>
        <div class="card-2">
          <div class="card-2-sub">
            <h4>Kuverit Community</h4>
            <div class="my-2" style="height:30px; color:yellow">Get More Free Tokens</div>
            <div style="height:30px;">
              <a href="https://t.me/KuveritNetwork" class="button">JOIN NOW</a>
            </div>
          </div>
        </div>

        <div class="mt-1 text-center" v-if="$store.state.Setting != null && $store.state.Setting.settings != null">
          <a href="#" class="me-1" @click="openLink($store.state.Setting.settings.social_link_x)">
            <svg height="35px" class="bg-white rounded" version="1.1" baseProfile="basic" id="Layer_1"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50"
              xml:space="preserve">
            <g>
              <path d="M39,4H11c-3.866,0-7,3.134-7,7v28c0,3.866,3.134,7,7,7h28c3.866,0,7-3.134,7-7V11C46,7.134,42.866,4,39,4z M29.978,37
                l-6.54-9.292L15.5,37H13l9.308-10.897L13.086,13h7.937l5.637,8.009L33.5,13H36l-8.211,9.613L37.914,37H29.978z"/>
              <polygon points="16.914,15 31.022,35 34.086,35 19.978,15 	"/>
            </g>
            </svg>
          </a>
          <a href="#" @click="openLink($store.state.Setting.settings.social_link_fb)">
            <svg height="35px" class="bg-white rounded" version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve">
            <g>
              <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="9.9929" y1="9.9929" x2="40.6152" y2="40.6152">
                <stop  offset="0" style="stop-color:#2AA4F4"/>
                <stop  offset="1" style="stop-color:#007AD9"/>
              </linearGradient>
              <path style="fill:url(#SVGID_1_);" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"/>
              <path style="fill:#FFFFFF;" d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46
                c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452
                C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"/>
            </g>
            </svg>
          </a>
        </div>

        <div class="reward-div mt-1" v-if="$store.state.User != null && $store.state.User.user != null">
          <h6>Your Rewards</h6>
          <div class="reward-card">
            <span>Account Age</span>
            <span>{{ $store.state.User.user.account_age_coin }} KUVT</span>
          </div>
          <div class="reward-card">
            <span>Telegram Premium</span>
            <span>{{ $store.state.User.user.premium_account_coin }} KUVT</span>
          </div>
          <div class="reward-card">
            <span>Invited Friends</span>
            <span>{{ $store.state.User.user.invited_friends_kuvt }} KUVT</span>
          </div>
          <div class="reward-card">
            <span>All KUVT</span>
            <span>{{ $store.state.User.user.coin_kuvt }} KUVT</span>
          </div>
          <div class="reward-card">
            <span>Gov Tokens</span>
            <span>{{ $store.state.User.user.coin_kvts }} KVTS</span>
          </div>
        </div>
      </div>
      <FooterNave />
    </div>
</template>
  
<script>
  import FirstPage from '/src/components/FirstPage.vue';
  import FooterNave from '/src/components/layout/footer.vue';
  // import HeaderNav from '/src/components/layout/header.vue';
  
  export default {
    name: 'HomePage',
    data() {
      return {
        isVisibleFirstPage: true
      };
    },
    components: {
      FirstPage,
      FooterNave,
      // HeaderNav
    },

    async mounted(){

      const script = document.createElement('script');
      script.src = "https://telegram.org/js/telegram-web-app.js";
      document.head.appendChild(script);

      setTimeout(() => {
        this.$store.commit('Other/setShowFirstScreen', false);
      }, 1000);

      // if(this.$store.state.User == null || this.$store.state.User.user == null){
      //   const urlParams = new URLSearchParams(window.location.search);
      //   const code = urlParams.get('tgWebAppStartParam');
      //   const hash = window.location.hash;
      //   const hashParams = new URLSearchParams(hash.substring(1));
      //   const encodedString = hashParams.get('tgWebAppData');
      //   const decodedString = decodeURIComponent(encodedString);
      //   const startIndex = decodedString.indexOf('{');
      //   const jsonStr = decodedString.substring(startIndex);
      //   let userObject = JSON.parse(jsonStr.split('&')[0]);
      //   if(userObject == null){
      //     userObject = {};
      //     userObject = {
      //       username: "hassan00942",
      //       first_name: "first_name",
      //       last_name: "last_name",
      //       id: "1",
      //     };
      //   }

      //   userObject.invitation_code = code;
      //   userObject.telegram_id = userObject.id;

      //   // call user api
      //   await this.callApi(userObject);
      // }
    },

    methods:{
      goToWithdraw() {
        this.$router.push({ name: 'withdraw' });
      },

      openLink(url) {
        if (window.Telegram && window.Telegram.WebApp) {
          window.Telegram.WebApp.openLink(url);
        } else {
          console.error('Telegram WebApp is not loaded');
        }
      }
      // async callApi(userObject){
      //   const response = await fetch(`https://miniapp.kuveritnetwork.com/admin/api/user/login`,{
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify(userObject)
      //   });

      //   const data = await response.json();
      //   this.$store.commit('User/setUser', data.data);

      //   console.log(data);
      // }
    }
  }
</script>
  
<style scoped>
  
  
  .card-1 .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }
  
  
  
  .card-1 {
    width: calc(100% - 0px);
    height: 137px;
    border-radius: 10px;
    z-index: 1;
    background: linear-gradient(90deg, #214E67 0%, #1F5674 100%);
  }
  
  .number-text {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 32px;
  }
  
  /* .card-1 button {
    background: #5395A3;
    color: #FFFFFF;
    width: 79px;
    height: 32px;
    top: 20px !important;
    border-radius: 5px;
    right: 10px;
    border: 0;
    cursor: pointer;
  } */
  
  .number-text small {
    font-weight: 500 !important;
    font-size: 14px !important;
  }
  
  .card-2 {
    width: calc(100% - 0px);
    margin-top:20px;
    /* height: 142px; */
    border-radius: 10px;
    border: 1px solid #5395A3;
    background: #17313F;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    box-sizing: border-box;
    /* left: 24px; */
  }
  
  .card-2-sub {
    text-align: center;
    margin-top:10px;
    margin-bottom:10px;
  }
  
  .card-2 h4 {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }
  
  .card-2 p {
    color: #5395A3;
    margin-top: 10px;
  }
  
  .card-2 button {
    width: 94px;
    height: 28px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    background: #5395a3;
    color: #FFFFFF;
    text-align: center;
  }
  
  .reward-div {
    color: #FFFFFF;
    left: 24px;
    /* margin-bottom: 10px; */
  }
  
  .reward-div h6 {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
  }
  
  .reward-card {
    width: calc(100% - 0px);
    height: 56px;
    border-radius: 10px;
    border: 1px solid #5395a3;
    background-color: #17313F;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    /* margin-bottom: 20px; */
    padding: 0 10px;
    box-sizing: border-box;
  }
  
  .reward-card span {
    color: #FFFFFF;
    margin: 5px;
  }
  
 
  </style>
  