export default {
  namespaced: true,
  state: {
    referralHistory: [],
    referralCount: 0,
    callApi: false,
  },
  mutations: {
    setReferralHistory(state, value) {
      state.referralHistory = value;
    },
    setReferralCount(state, value) {
      state.referralCount = value;
    },
    setCallApi(state, value) {
      state.callApi = value;
    },
    
  },
  actions: {
    
  },
  getters: {
    
  },
};