<template>
    <div class="home bg-img">
        <HeaderNav title="Buy Token" />
        <div class="content">
            <div class="text">
                <p>How many Tokens you want to buy?</p>
            </div>
            <div class="">
                <input type="number" step="1" min="1" class="start" 
                v-model="numberOfTokenBuy"
                @input="onTokenInput"
                 />
            </div>
            <div class="price-container">
                <small class="price">Total Price : ${{ totalPrice.toFixed(2) }}</small>
            </div>
        
            <button class="full-width-btn" @click="goToBuyTokens">Confirm</button>
        </div>
    </div>
</template>

<script>
import HeaderNav from '/src/components/layout/header.vue';
  
export default {
    name: 'BuyToken2',
     data() {
      return {
        numberOfTokenBuy: null,
        totalPrice: 0,
      };
    },
    components: {
        HeaderNav
    },
    methods:{
        goBack() {
           this.$router.go(-1);
        },
        goToBuyTokens() {
            this.$router.push({ name: 'deposit' });
        },
        calculatePrice() {
            const tokenPrice = this.$store.state.Setting.settings.sale_token_price;
            this.totalPrice = this.numberOfTokenBuy * tokenPrice;
        },
        onTokenInput() {
            const maxToken = this.$store.state.Setting.settings.sale_token_available;

            if (this.numberOfTokenBuy > maxToken) {
                this.numberOfTokenBuy = maxToken;
                alert(`You can only buy up to ${maxToken} tokens.`);
            }
            this.calculatePrice();
        }
    }
}
</script>

<style scoped>
.inline-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5395A3;
    width: 100%;
    padding: 30px 0;
}

.inline-container img.corse-icon {
    margin-left: 24px;
    cursor: pointer;
}

.inline-container h6 {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    flex: 1;
}
.text{
    margin: 10px;
    padding: 10px;
    text-align: center;
    color: #fff;
}
/* .tokan-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
    background: #17313F;
    color: #fff;
    border-radius: 10px;
    border: 1px solid #5395A3;
} */
.full-width-btn {
    padding: 10px;
    margin: 10px 0;
    margin-top: 30px;
    width: 100%;
    padding: 15px 0;
    background-color: #5395A3;
    color: #fff;
    border: none;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    border-radius: 10px;
}
.price-container {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
    padding: 10px;
}

.price {
    color: #fff;
}

</style>
