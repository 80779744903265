<template>
    <div class="home bg-img">
        <HeaderNav title="Leaderboard" />
        <div class="content">
            <div class="position-card"> 
                <div class="left-div">
                    <p>Your Position</p>
                    <h4>{{ yourRank }} <sup>{{ getOrdinalSuffix(yourRank) }}</sup></h4>
                    <a href="https://t.me/KuveritNetworkOfficial" class="btn left-div-btn">Kuverit_official</a>
                </div>
                <div class="right-div" v-if="$store.state.User">
                    <h3>{{ $store.state.User.user.coin_kuvt }} <small>KUVT</small></h3>
                    <h3>{{ $store.state.User.user.coin_kvts }} <small>KVTS</small></h3>
                </div>
            </div>
            <div class="postion-1-2-3">
                <div class="first" v-if="userFirst">
                    <!-- <img src="@/assets/img/1st.png" alt=""> -->
                    <img class="rounded-pill" v-if="userFirst.profile_image" :src="userFirst.profile_image" height="45px">
                    <img class="rounded-pill" v-else src="/assets/profile.jpg" height="45px">
                    <p>1<sup>st</sup></p>
                    <small>@{{ userFirst.user_name }}</small>
                </div>
                <div class="second" v-if="userSecond">
                    <!-- <img src="@/assets/img/2nd.png" alt=""> -->
                    <img class="rounded-pill" v-if="userSecond.profile_image" :src="userSecond.profile_image" height="45px">
                    <img class="rounded-pill" v-else src="/assets/profile.jpg" height="45px">
                    <p>2<sup>nd</sup></p>
                    <small>@{{ userSecond.user_name }}</small>
                </div>
                <div class="third" v-if="userThird">
                    <!-- <img src="@/assets/img/3rd.png" alt=""> -->
                    <img class="rounded-pill" v-if="userThird.profile_image" :src="userThird.profile_image" height="45px">
                    <img class="rounded-pill" v-else src="/assets/profile.jpg" height="45px">
                    <p>3<sup>rd</sup></p>
                    <small>@{{ userThird.user_name }}</small>
                </div>
            </div>
            <div class="other-postion">
                <div class="other-postion-card rounded" v-for="(user, index) in users.slice(3)" :key="user.id">
                    <div>
                        <img class="float-start rounded-pill" v-if="user.profile_image" :src="user.profile_image" height="45px">
                        <img class="float-start rounded-pill" v-else src="/assets/profile.jpg" height="45px">
                        <div class="float-start ms-2">
                            <div class="inline-text">{{ index + 4 }}{{getOrdinalSuffix(index + 4)}}</div>
                            <div class="user-text">@{{ user.user_name }}</div>
                        </div>
                    </div>
                    <div class="end-div">
                        <p>{{ user.coin_kuvt }} KUVT</p>
                        <p>{{ user.coin_kvts }} KVTS</p>
                    </div>
                </div>
            </div>
        </div>
        <FooterNave />
    </div>
</template>
<script>
import FooterNave from '/src/components/layout/footer.vue';
import HeaderNav from '/src/components/layout/header.vue';

export default{
    name: 'LeaderBoard',
    data() {
      return {
        users: [],
        userFirst: null,
        userSecond: null,
        userThird: null,
        yourRank: 0,
      };
    },
    components: {
        FooterNave,
        HeaderNav,
    },
    mounted(){
        this.users = this.$store.state.Leaderboard.users;
        this.yourRank = this.$store.state.Leaderboard.yourRank;

        if(this.$store.state.Leaderboard.callApi == false){
            this.callApi();
        }else{
            this.setFirstThreeUsers(this.users);
        }
    },
    methods: {
         getOrdinalSuffix(number) {
            const j = number % 10;
            const k = number % 100;
            if (j === 1 && k !== 11) {
                return 'st';
            }
            if (j === 2 && k !== 12) {
                return 'nd';
            }
            if (j === 3 && k !== 13) {
                return 'rd';
            }
            return 'th';
        },
        async callApi(){
            let user_id = this.$store.state.User.user.id;
            const response = await fetch(`https://miniapp.kuveritnetwork.com/admin/api/ledgerboard?user_id=${user_id}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            let data = await response.json();
            data = data.data;

            this.setFirstThreeUsers(data.users);

            // if (data.users.length >= 3) {
            //     data.users.splice(-3);
            // }else if (data.users.length >= 2) {
            //     data.users.splice(-2);
            // }else if (data.users.length >= 1) {
            //     data.users.splice(-1);
            // }

            // Set the users array to the remaining users
            this.users = data.users;
            this.yourRank = data.your_rank;

            this.$store.commit('Leaderboard/setUsers', data.users);
            this.$store.commit('Leaderboard/setYourRank', data.counts);
            this.$store.commit('Leaderboard/setCallApi', true);

            // this.$store.commit('User/setUser', data.data);
        },

        setFirstThreeUsers(users){
            this.userFirst = users[0] || null;
            this.userSecond = users[1] || null;
            this.userThird = users[2] || null;
        }
    }
}
</script>
<style scoped>
  .header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}
  .corse-icon{
    top: 55px;
    left: 24px;
    position: absolute;
  }
  .header h6{
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin-top: 38px;
  }

.first {
    position: absolute;
    /* width: 69px; */
    height: 123px;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    text-align: center;
}
.second{
    position: absolute;
    /* width: 71px; */
    height: 123px;
    top: 100px;
    left: 20px;
    color: #fff;
    text-align: center;
}
.third{
    position: absolute;
    /* width: 64px; */
    height: 123px;
    top: 100px;
    right: 20px;
    color: #fff;
    text-align: center;
}
.other-postion{
}

.other-postion-card {
    position: relative;
    /* width: 100%; */
    /* height: 100%; */
    margin-top: 10px;
    width: calc(100% - 22px);
    border: 1px solid #5395A3;
    background: #17313F;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
}


.other-postion-card span {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    z-index: 1;
}

.other-postion-card .inline-text {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    z-index: 1;
}

.other-postion-card .user-text {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    z-index: 1;
}

.end-div {
    color: #fff;
    font-size: 14px;
    text-align: center;
    z-index: 1;
    font-weight: 700;
    font-size: 14px;
}

.end-div p {
    margin: 0;
}



</style>