// store/index.js
import Vuex from 'vuex';
import Leaderboard from './Leaderboard';
import Other from './Other';
import ReferralHistory from './ReferralHistory';
import User from './User';
import Setting from './Setting';

const store = new Vuex.Store({
  modules: {
    Leaderboard,
    Other,
    ReferralHistory,
    User,
    Setting,
  },
});

export default store;
