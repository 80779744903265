<template>
  <RouterView />
</template>

<script setup>
  import { RouterView } from 'vue-router'
  import { onMounted } from 'vue';
  import { useStore } from 'vuex';
  const store = useStore();

  const fetchUserData = async () => {
    if(store.state.User == null || store.state.User.user == null){
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('tgWebAppStartParam');
      const hash = window.location.hash;
      const hashParams = new URLSearchParams(hash.substring(1));
      const encodedString = hashParams.get('tgWebAppData');
      const decodedString = decodeURIComponent(encodedString);
      const startIndex = decodedString.indexOf('{');
      const jsonStr = decodedString.substring(startIndex);
      let userObject = JSON.parse(jsonStr.split('&')[0]);
      if(userObject == null){
        userObject = {};
        userObject = {
          username: "test",
          first_name: "first",
          last_name: "last",
          id: "1",
          // is_premium: false,
        };
      }

      userObject.invitation_code = code;
      userObject.telegram_id = userObject.id;

      // call user api

      const response = await fetch(`https://miniapp.kuveritnetwork.com/admin/api/user/login`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userObject)
      });

      const data = await response.json();
      store.commit('User/setUser', data.data);
    }
  }

  const fetchSettingData = async () => {
    const response = await fetch(`https://miniapp.kuveritnetwork.com/admin/api/settings`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    store.commit('Setting/setSettings', data.data);
  };

  onMounted(() => {
    fetchUserData();
    fetchSettingData();
  });
</script>
<style>
  * {
    margin: 0;
    padding: 0;
  }
  body {
    background: url('@/assets/img/all-bg.png') no-repeat center center;
    background-size: cover;
    margin: 0;
  }

  @media (max-width: 768px) {
    body {
      background-size: cover;
      min-height: 100vh;
      margin: 0;
    }
  }
</style>