<template>
    <div class="image-container">
      <!-- <img src="@/assets/img/corse-icon.png" alt="Course Icon" class="corse-icon"> -->
      <img src="@/assets/img/home-icon.png" alt="Home Icon" class="home-icon">
    </div>
</template>
  
<script>
  export default {
      name: 'FirstPage',
      // mounted(){
      //   setTimeout(() => {
      //     this.$router.push('/home');
      //   },1000);
      // }
  }
</script>

<style scoped>
  .image-container {
    display: none;
    width: 100%;
    height: 100vh;
    /*background: url('@/assets/img/home-img.png') no-repeat center center;*/
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .corse-icon {
    position: absolute;
    top: 15px;
    left: 10px;
    margin: 10px;
  }
  
  .home-icon {
    max-width: 100%;
    max-height: 100%;
  }
  
  @media (max-width: 768px) {
    .image-container {
      display: flex; /* Show on mobile screens and enable flexbox */
    }
  }
  </style>
  