<template>
    <!-- <div class="inline-container">
        <img 
            src="@/assets/img/back-icon.png" 
            alt="Back Icon" 
            class="corse-icon" 
            @click="goBack"
        >
        <h6>Deposit</h6>
    </div> -->
    <div class="home bg-img">
        <HeaderNav title="Leaderboard" />
        <div class="content">
            <div class="barcode-div" >
                <img src="@/assets/img/barcode.png" alt="">
            </div>
            <div class="detail-div">
                <div>
                    <label for="mainnet"> Mainnet</label>
                    <select name="mainnet" id="mainnet">
                        <option value="" selected>BNB Smart Chain (BEP20)</option>
                    </select>
                </div>
                <div class="input-wrapper">
                    <label for="address">Address</label>
                    <div class="input-container">
                        <div type="text" class="c-show" value="0515x54s84da5d1a1c5151ads" readonly>
                            0xFF00000000000000000000
                        </div>
                        <img src="@/assets/img/coyp-icon.png" alt="Copy Icon" class="copy-icon">
                    </div>
                </div>
                <!-- <div>
                    <label for="contract">Contract</label>
                    <input type="text" id="contract" value="0xjhh322jkh3kj2h3k2hke2kj332">
                </div> -->
            </div>
            <button class="full-width-btn"  @click="showPopup">Save and Share address</button>

            <div v-if="isPopupVisible" class="popup-container" @click.self="closePopup">
                <div class="popup-content">
                    <img src="@/assets/img/withdraw-success.png" alt="Success Icon">
                    <h2>Token Bought Successfully</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <button class="go-back-btn" @click="closePopup">Go Back</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderNav from '/src/components/layout/header.vue';
  
export default{
    name: 'DepositScreen',
    data() {
        return {
            isPopupVisible: false
        };
    },
    components: {
        HeaderNav
    },
    methods: {
        showPopup() {
            this.isPopupVisible = true;
        },
        closePopup() {
        this.isPopupVisible = false;
        },
        goBack() {
           this.$router.go(-1);
        },
    }
}
</script>
<style scoped>
.inline-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5395A3;
    width: 100%;
    padding: 30px 0;
}

.inline-container img.corse-icon {
    margin-left: 24px;
    cursor: pointer;
}

.inline-container h6 {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    flex: 1;
}
.barcode-div{
    text-align: center;
    margin-top: 20px;
}
.detail-div{
    color: #fff;
    width: 100%;
}
/* #mainnet{
    width: 100%;
    background: #17313F;
    border: 1px solid #5395A3;
    border-radius: 10px;
    padding: 10px;
    color: #fff;
    margin: 10px 0;
} */
.input-wrapper {
  position: relative;
  width: 100%;
}

.input-container {
  position: relative;
  width: 100%;
}

/* #address {
  width: 100%;
  background: #17313F;
  border: 1px solid #5395A3;
  border-radius: 10px;
  padding: 10px 40px 10px 10px;
  color: #fff;
  margin: 10px 0;
  box-sizing: border-box;
} */

.copy-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  /* width: 24px; */
  /* height: 20px; */
}
#contract{
    width: 100%;
    background: #17313F;
    border: 1px solid #5395A3;
    border-radius: 10px;
    padding: 10px 40px 10px 10px;
    color: #fff;
    margin: 10px 0;
    box-sizing: border-box;
}
.full-width-btn {
    /* padding: 10px; */
    /* margin: 10px; */
    margin-top: 30px;
    width: 100%;
    padding: 15px 0;
    background-color: #5395A3;
    color: #fff;
    border: none;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    border-radius: 10px;
    margin-bottom: 10px;
}

.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.popup-content {
  background-color: #1E1E1E;
  border-radius: 10px;
  width: 300px;
  padding: 20px;
  text-align: center;
  color: #fff;
}

.popup-content img {
  width: 50px;
  margin-bottom: 20px;
}

.go-back-btn {
  background-color: #5395A3;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 50px;
}
</style>