import { createRouter, createWebHistory } from 'vue-router'
// import FirstPage from '../components/FirstPage.vue'
import HomePage from '../components/Pages/Home.vue'
import LeaderBoard from '../components/Pages/Leaderboard.vue'
import FriendsPage from '../components/Pages/Friends.vue'
import WithDraw from '../components/Pages/Withdraw.vue'
import BuyToken1 from '../components/Pages/BuyToken1.vue'
import BuyToken2 from '../components/Pages/BuyToken2.vue'
import DepositScreen from '../components/Pages/Deposit.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        // {
        //     path: '/',
        //     component: FirstPage
        // },
        {
            path: '/',
            name: 'home',
            component: HomePage
        },
        {
            path: '/leaderboard',
            name: 'leaderboard',
            component: LeaderBoard
        },
        {
            path: '/friends',
            name: 'friends',
            component: FriendsPage
        },
        {
            path: '/withdraw',
            name: 'withdraw',
            component: WithDraw
        },
        {
            path: '/buy/token-1',
            name: 'buy/token-1',
            component: BuyToken1
        },
        {
            path: '/buy/token-2',
            name: 'buy/token-2',
            component: BuyToken2
        },
        {
            path: '/deposit/screen',
            name: 'deposit',
            component: DepositScreen
        }
    ]
});

export default router
