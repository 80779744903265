<template>
    <footer class="footer">
      <div 
        class="icon cursor-pointer" 
        :class="{ active: $route.name == 'home' }"
        @click="navigateTo('home')"
      >
        <img src="@/assets/img/home_icon.png" alt="Home">
        <span>Home</span>
      </div>
      <div 
        class="icon cursor-pointer" 
        :class="{ active: $route.name == 'leaderboard' }"
        @click="navigateTo('leaderboard')"
      >
        <img src="@/assets/img/leaderboard.png" alt="Leaderboard">
        <span>Leaderboard</span>
      </div>
      <div 
        class="icon cursor-pointer" 
        :class="{ active: $route.name == 'friends' }"
        @click="navigateTo('friends')"
      >
        <img src="@/assets/img/friends.png" alt="Friends">
        <span>Friends</span>
      </div>
      <!-- <div 
        class="icon cursor-pointer" 
        :class="{ active: $route.name == 'ico' }"
        @click="navigateTo('buy/token-1')"
      >
        <img src="@/assets/img/ico.png" alt="Additional">
        <span>ICO</span>
      </div> -->
    </footer>
</template>
  
<script>
  export default {
    name: 'FooterNave',
    data() {
      return {
        activeIcon: 'home'
      };
    },
    methods: {
      navigateTo(routeName) {
        // this.activeIcon = routeName;
        this.$router.push({ name: routeName });
      }
    }
  };
  </script>
<style scoped>
.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #17313F;
  border-top: 1px solid #5395A3;
  padding: 10px;
  color: #FFFFFF;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99999;
}

.footer .icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
}

.footer .icon img {
  width: 24px;
  height: 24px;
}

.footer .icon span {
  font-size: 12px;
  margin-top: 5px;
  color: #ffffff;
}

.footer .icon.active::before {
  content: '';
  position: absolute;
  top: -10px;
  width: 100%;
  height: 2px;
  background-color: #5395A3;
}

.footer .icon.active img,
.footer .icon.active span {
  /* color: #5395A3; */
  color: #ffffff;
}
</style>
  