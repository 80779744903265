export default {
  namespaced: true,
  state: {
    settings: null,
  },
  mutations: {
    setSettings(state, value) {
      state.settings = value;
    },
  },
  actions: {
    // async fetchSettings({ commit }) {
    //   try {
    //     let response = await axios.get('https://admin.ambeeaesthetics.co.uk/api/settings');
    //     commit('setSettings', response.data);
    //     alert(response.data.website_title);
    //     document.title = response.data.website_title;
    //   } catch (error) {
    //     console.error("Error fetching settings:", error);
    //   }
    // },
  },
  getters: {
    getSettings(state) {
      return state.settings;
    },
  },
};